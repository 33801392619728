import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Homepage } from './homepage';
import { LoginPage } from './loginpage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Homepage/>}/>
        <Route path='/test' element={<Homepage/>}/>
        <Route path='/login' element={<LoginPage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
