import { Image } from "../sdk/ui";

//Make image absolute and text relative to fit content properly
const Splash = (props) => {

    const {image, content} = props;

    return <>
        <div className='splashContainer'>
            <div className='splashImageContainer'>
                <Image src={image} className='splashImage'/>
            </div>
            <div className="splashContent">
                <div className='splashText'>{content}</div>
            </div>
        </div>
    </>;
};

export default Splash;