import { Heading, TextField, Text, Button } from "../sdk/ui";
import { CallService } from "../sdk/services";
import { useState } from "react";
import React from "react";

const LoginPage = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");

    const SubmitHandler = () => {
        CallService("login", {user: username, pass: password}, LoginResult, false);
    };

    const LoginResult = (response) => {
        if (!response.success) {
            return;
        }

        setDisplayName(response.name);

    };

    return <>
        <Heading>Login</Heading>
        {displayName ? <Text>Welcome, {displayName}</Text> : 
        <>
            <Text inline={true}>Username: </Text><TextField setValue={setUsername}/>
            <Text inline={true}>Password: </Text><TextField setValue={setPassword}/>
            <Button onClick={SubmitHandler} text={"Login"} />
        </>
        }
    </>;
};

export default LoginPage;