import Header from "./Header";
import Splash from "./Splash";
import Instagram from "./Instagram";
import { Link } from "../sdk/ui";

const splashText = <>
    Willowbrook Honey is a local family beekeeping business in Terre Haute, Indiana owned and operated by brothers Eric and Mark Ellis. If you are interested in ordering please
    <Link url="mailto:lovewillowbrookhoney@gmail.com"> email us</Link> or call at (812)240-4257.
</>;

const Homepage = () => {
    return <>
        <Header/>
        <Splash image={'/images/homeSplash/bees.jpg'} content={splashText}/>
    </>
};

export default Homepage;